import React from 'react';
import './App.css';

function App() {
  return (
    <div className='h-screen w-screen bg-gray-800 flex'>
      <div className='m-auto grow'>
        <div className='p-8 lg:p-32 max-w-screen-md'>
          <h1 className="text-5xl lg:text-9xl font-bold text-lime-300">
            GET WORKOUTS
          </h1>
          <p className='mt-4 text-l lg:text-2xl text-white font-bold'>PLAIN SIMPLE. YOU NEED A WORKOUT. WE DELIVER WORKOUTS.</p>
          <div className='mt-8'>
            <span className='text-base text-white font-extralight'>Our technology is designed for delivering the workout you need when you need it. We have processed hundreds of thousands of individual Workouts and training plans for generating a next level AI. The result for you?</span><span className='font-bold text-white'> Your next Workout is one button away.</span> 
          </div>
          <div className="mt-8 text-lime-300 font-extralight">
            COMING SOON
          </div>
        </div>
      </div>
    </div>
  );  
}

export default App;
